export const environment = {
  production: true,
  raygun: {
    apiKey: 'sIXGUajP7bTPB9Tw5bqHmg'
  },
  urls: {
    api: '/api',
    manage: 'https://manage-dev.esalon.nz/'
  }
};
